<template>
  <div ref="canvas" style="line-height: 0;">
    <div v-for="item in stats" style="width: 25mm;display: inline-block;height: 6mm;margin: 0 2mm 2mm 0;">
      <svg width="6mm" height="6mm" viewBox="0 0 25 25" version="1.1"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink" style="display: inline-block;vertical-align: middle;">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Artboard" :fill="'#' + item.color.hex" v-html="item.color.svg">
          </g>
        </g>
      </svg>
      <span style="line-height: 6mm;vertical-align: middle;display: inline-block;color: #777776;font-size: 5mm;margin-left: 1mm;">
        {{ item.count }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: "PlateLegend",
  data() {
    return {
      stats: null,
    }
  },
  props: ['project', 'index', 'colors'],
  computed: {},
  methods: {},
  mounted() {
    const stats = {}
    var lastColorId = 0
    if (this.index === 'all') {
      const counter = this.project.meta.plate_size * this.project.meta.plate_size * this.project.meta.canvas_width * this.project.meta.canvas_height
      for (let i = 0; i < counter; i++) {
        for (let layer of this.project.layers) {
          if (layer.data[i] > 0) {
            lastColorId = layer.data[i]
            break
          }
        }

        if (lastColorId in stats) {
          stats[lastColorId] += 1
        } else {
          stats[lastColorId] = 1
        }
      }
    } else {
      const x = this.index % this.project.meta.canvas_width
      const y = Math.floor(this.index / this.project.meta.canvas_width)

      let startX = x * this.project.meta.plate_size
      let endX = startX + this.project.meta.plate_size
      let startY = y * this.project.meta.plate_size
      let endY = startY + this.project.meta.plate_size

      for (let y = startY; y < endY; y++) {
        for (let x = startX; x < endX; x++) {
          let fetchIndex = y * this.project.meta.plate_size * this.project.meta.canvas_width + x
          for (let layer of this.project.layers) {
            if (layer.data[fetchIndex] > 0) {
              lastColorId = layer.data[fetchIndex]
              break
            }
          }
          if (lastColorId in stats) {
            stats[lastColorId] += 1
          } else {
            stats[lastColorId] = 1
          }
        }
      }
    }


    const colorsMap = {}
    for (let item of this.colors) {
      colorsMap[item.id] = item;
    }

    const result = []
    for (let item in stats) {
      result.push({
        color_id: item,
        color: colorsMap[item],
        count: stats[item]
      })
    }

    this.stats = result
  }
}
</script>

<style scoped>

</style>
